import React from 'react';
import SalePrice from './salePrice';
import colors from '../config/color';

const BigProductCard = () => {
  return (
    <div className="content">
      <div className="ring-img"></div>
      <h2>925 純銀；矽膠親膚材質</h2>
      <div className="price">
        <SalePrice />
      </div>

      <style jsx>{`
        .content {
          width: 100%;
          background-color: ${colors['black']};
          text-align: center;
          /* 以下為視差做法 */
          position: fixed;
          bottom: 0;
          z-index: -1;
          height: 660px;
        }

        .ring-img {
          content: url('https://via.placeholder.com/318');
          margin: auto;
        }

        h2 {
          color: ${colors['white']};
          font-family: NotoSansCJKtc-Medium;
          font-size: 36px;
          line-height: 60px;
          margin: 0 16px;
          margin-top: 65px;
        }

        .price {
          margin-top: 35px;
        }

        @media (min-width: 768px) {
          .content {
            height: 1250px;
          }

          .ring-img {
            width: 85%;
            height: 85%;
            object-fit: contain;
            content: url('https://via.placeholder.com/1019');
          }

          h2 {
            font-size: 48px;
            margin: 0 60px;
            margin-top: -440px;
          }
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

BigProductCard.propTypes = {};

BigProductCard.defaultProps = {};

export default BigProductCard;
