import React from 'react';
import PropTypes from 'prop-types';
import colors from '../config/color';

const PriceIntoCard = props => {
  const { text, price, onPress } = props;

  return (
    <div className="content" onClick={onPress}>
      <p>CLASSIC</p>
      <div className="price-title">
        {/* <h5>Here Hear 戒指</h5> */}
        <h5 className="price-highlight">{text}</h5>
      </div>
      <p className="price-text">NT$ {price}</p>
      <h6>加入購物車 ></h6>

      <style jsx>{`
        .content {
          text-align: center;
          width: 100%;
          padding: 17px 9px;
          margin: 0 2.5px;
          border: 1px ${colors['black-p']} solid;
          border-radius: 10px;
        }

        .content:hover {
          border: 2px ${colors['slime-green']} solid;
        }

        p {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Medium;
          font-size: 14px;
          line-height: 20px;
        }

        .price-title {
          margin: 10px 0;
        }

        h5 {
          color: ${colors['white']};
          font-family: NotoSansCJKtc-Medium;
          font-size: 16px;
          line-height: 24px;
        }

        .price-highlight {
          color: ${colors['white']};
          font-family: NotoSansCJKtc-Medium;
          font-size: 16px;
          line-height: 24px;
        }

        .price-text {
          color: ${colors['white']};
          margin: 32px 0;
          font-family: NotoSansCJKtc-Black;
          font-size: 14px;
          line-height: 20px;
        }

        h6 {
          color: ${colors['purple']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 12px;
          line-height: 18px;
        }

        @media (min-width: 768px) {
          .content {
            padding: 45px 33px;
            margin: 0 7.5px;
          }

          p {
            font-size: 28px;
            line-height: 41px;
          }

          .price-title {
            margin: 25px 0;
          }

          h5 {
            font-size: 32px;
            line-height: 47px;
          }

          .price-highlight {
            font-size: 32px;
            line-height: 47px;
          }

          .price-text {
            margin: 34px 0;
            font-size: 36px;
            line-height: 54px;
          }

          h6 {
            font-size: 20px;
            line-height: 20px;
          }
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

PriceIntoCard.propTypes = {
  text: PropTypes.string,
  price: PropTypes.number,
  onPress: PropTypes.func,
};

PriceIntoCard.defaultProps = {
  text: '　',
  price: '',
  onPress: () => {},
};

export default PriceIntoCard;
