import React, { Component } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Header from '../components/header';
import SalePrice from '../components/salePrice';
import IntroList from '../components/introList';
import IntroPrice from '../components/introPrice';
import ServiceList from '../components/serviceList';
import BigProductCard from '../components/bigProductCard';
import colors from '../config/color';

class Homepage extends Component {
  render() {
    // const data = this.props.data;
    return (
      <Layout>
        {/* TODO: SEO */}
        <SEO title="Here Hear 聽聽" />
        <div className="content">
          <Header title="戒指" />
          <h1>常保活力，常保健康，保持放鬆</h1>
          <div className="banner-mask"></div>
          <SalePrice />
          <div className="banner-ring-img">
            {/* <img src="https://via.placeholder.com/265x297"></img> */}
          </div>
        </div>
        <div className="text-content">
          <p>
            時刻監測你的心率、追蹤你的睡眠品質、激勵你達成健康目標，還能讓你時時保持對壓力的覺察，立即紓解生活累積的壓力。有了 Here
            Hear Ring，小到用一個戒指完成。
          </p>
        </div>
        <IntroList />
        <IntroPrice />
        <div className="box">
          <ServiceList />
        </div>
        {/* box 為視差需求 */}
        <div className="boxx">
          <BigProductCard />
        </div>

        <style jsx>{`
          .content {
            background-image: url('/images/happy-businessman-celebrating-victory-at-office-PV4ZPYY.png');
            background-repeat: no-repeat;
            background-position: center;
            width: 100%;
            height: 600px;
            text-align: center;
            position: relative;
            z-index: 0;
          }

          .banner-mask {
            width: 100%;
            height: 600px;
            background-color: ${colors['black']};
            opacity: 0.3;
            position: absolute;
            top: 0;
            z-index: -1;
          }

          h1 {
            color: ${colors['white']};
            font-family: NotoSansCJKtc-Bold;
            font-size: 42px;
            line-height: 60px;
            margin: 112px 0 64px 0;
          }

          .banner-ring-img {
            padding-top: 40px;
            margin: auto;
            content: url('https://via.placeholder.com/265x297');
          }

          .text-content {
            color: ${colors['black-p']};
            font-family: NotoSansCJKtc-Regular;
            font-size: 14px;
            line-height: 20px;
            padding: 0 16px;
            margin-top: 140px;
          }

          .box {
            overflow: hidden;
          }

          .boxx {
            margin-bottom: 500px;
          }

          @media (min-width: 768px) {
            .content {
              background-image: url('/images/happy-businessman-riding-bicycle-to-work-in-CQDRP6Z.png');
              background-size: cover;
              
            }

            h1 {
              font-size: 48px;
              line-height: 60px;
              margin: 100px 0 46px 0;
            }

            .banner-ring-img {
              padding-top: 44px;
              content: url('https://via.placeholder.com/380x426');
            }

            .text-content {
              font-size: 18px;
              line-height: 27px;
              padding: 0 60px;
              margin-top: 250px;
          }

            .boxx {
              margin-bottom: 752px;
            }
        `}</style>
      </Layout>
    );
  }
}

export default Homepage;

export const pageQuery = {};
