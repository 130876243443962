import React from 'react';
import PropTypes from 'prop-types';
import ShoppingButton from './shoppingButton';
import colors from '../config/color';

const IntroCard = props => {
  const { title, text, imageUri, imageAlt, imagePosition } = props;

  return (
    <div className="content">
      <div className="text-content">
        <h2>{title}</h2>
        <p>{text}</p>
        <div className="shopping">
          <ShoppingButton text="加入購物車" />
        </div>
      </div>
      <img className="img-content" alt={imageAlt} src={imageUri} />

      <style jsx>{`
        .content {
          margin-top: 70px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .text-content {
          display: flex;
          flex-direction: column;
        }

        h2 {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Medium;
          font-size: 36px;
          line-height: 60px;
          padding: 0 16px;
        }

        p {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 14px;
          line-height: 20px;
          padding: 0 16px;
          margin-top: 10px;
        }

        .shopping {
          padding: 15px 0 15px 16px;
        }

        .img-content {
          width: 100%;
          margin-bottom: -0.5px;
        }

        @media (min-width: 768px) {
          .content {
            margin-top: 0;
            flex-direction: ${
              imagePosition === 'right' ? 'row' : 'row-reverse'
            };
          }

          .text-content { 
            padding: 20px 0;
            display: flex;
            justify-content: center;
          }

          .img-content {
            width: 54%;
            object-fit: cover;
          }

          h2 {
            font-size: 48px;
            padding: 0 40px 0 60px;
          }

          p {
            font-size: 18px;
            line-height: 27px;
            padding: 0 40px 0 60px;
            margin-top: 24px;
          }

          .shopping {
            padding: 24px 0 0 60px;
          }

      `}</style>
    </div>
  );
};

IntroCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  imageUri: PropTypes.string,
  imageAlt: PropTypes.string,
  imagePosition: PropTypes.string,
};

IntroCard.defaultProps = {
  title: '',
  text: '',
  imageUri: '',
  imageAlt: '',
  imagePosition: 'right',
};

export default IntroCard;
