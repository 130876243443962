import React from 'react';
import PropTypes from 'prop-types';
import colors from '../config/color';

const IntroCard = props => {
  const { title, text, imageUri, imageAlt, showActionBtn } = props;

  return (
    <div className="content">
      <div className="flex_box">
        <img className="img-content" alt={imageAlt} src={imageUri} />
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
      <div className="flex_boxx">
        {showActionBtn ? <a herf="#">進一步了解 ></a> : null}
      </div>

      <style jsx>{`
        .content {
          width: 285px;
          min-height: 300px;
          padding: 20px 52px;
          text-align: center;
        }

        .img-content {
          border-radius: 100px;
        }

        h3 {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Medium;
          font-size: 28px;
          line-height: 40px;
          margin: 15px 0;
        }

        p {
          color: ${colors['black-p']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 18px;
          line-height: 27px;
        }

        a {
          display: block;
          color: ${colors['purple']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 16px;
          line-height: 24px;
          margin-top: 30px;
        }

        @media (min-width: 768px) {
          .content {
            min-height: 420px;
            padding: 60px 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }

          .img-content {
            width: 120px;
          }

          .flex_box {
            /* display: flex;
            flex-direction: column;
            align-items: center;
            flex: 4; */
          }

          .flex_boxx {
            /* display: flex;
            justify-content: center;
            flex: 1; */
          }
        }

        @media (min-width: 816px) {
          .content {
            min-height: 393px;
          }
        }

        @media (min-width: 960px) {
          .content {
            min-height: 366px;
          }
        }

        @media (min-width: 1024px) {
          .content {
            min-height: 326px;
          }
        }

        @media (min-width: 1200px) {
          .content {
            min-height: 326px;
          }

        @media (min-width: 1320px) {
        .content {
          min-height: 299px;
        }
      `}</style>
    </div>
  );
};

IntroCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  imageUri: PropTypes.string,
  imageAlt: PropTypes.string,
  showActionBtn: PropTypes.bool,
};

IntroCard.defaultProps = {
  title: '',
  text: '',
  imageUri: '',
  imageAlt: '',
  showActionBtn: '',
};

export default IntroCard;
