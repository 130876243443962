import React from 'react';
import IntroCard from '../components/introCard';

const IntroList = () => {
  return (
    <div className="content">
      <IntroCard
        title="追蹤睡眠，提升健康"
        text="Here Hear 戒指會顯示你每天的睡眠狀況。看看你是不是進入熟睡、幫你測量實際休息的時間、追蹤你的睡眠品質，還可以利用 App 內提供的功能改善睡眠。你還能試試內建的「正念冥想」功能，在需要時，讓 APP 幫助你改善睡眠狀況。"
        imageUri="/images/happy-pregnant-woman-sleeping-in-bed-at-home-P7584TL@3x.png"
        imagePosition="right"
      />
      <IntroCard
        title="監控心率，紓解壓力"
        text="你可以追蹤自己的心率變異性，瞭解自己的交感神經、副交感神經變化情形，以 App 的事件標記功能，來追蹤你的日常生活壓力，適度休息調整心情。你還能以 APP 內提供的音樂、冥想、文章，來紓解這些壓力，輕鬆保持心理健康。"
        imageUri="/images/man-using-fitness-bracelet-during-morning-run-PGHTMYE@3x.png"
        imagePosition="left"
      />

      <style jsx>{`
        @media (min-width: 768px) {
          .content {
            margin-top: 80px;
          }
        }
        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

IntroList.propTypes = {};

IntroList.defaultProps = {};

export default IntroList;
