import React from 'react';
import colors from '../config/color';

const SalePrice = () => {
  return (
    <div className="content">
      <h4>現在售價 NT$ 4,980 起</h4>

      <style jsx>{`
        h4 {
          color: ${colors['white']};
          font-family: NotoSansCJKtc-Medium;
          font-size: 21px;
        }

        @media (min-width: 768px) {
          h4 {
            font-size: 36px; 
          }

          @media (min-width: 1200px) {
          }
      `}</style>
    </div>
  );
};

SalePrice.propTypes = {};

SalePrice.defaultProps = {};

export default SalePrice;
