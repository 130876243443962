import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import colors from '../config/color';

const ShoppingButton = props => {
  const { text, btnStyle } = props;
  return (
    <div style={btnStyle} className="button-content">
      <div>{text}</div>

      <style jsx>{`
        .button-content {
          color: ${colors['purple']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 18px;
          line-height: 27px;
          border: 1px ${colors['purple']} solid;
          border-radius: 10px;
          padding: 7px 24px;
          display: inline-flex;
          
        }

        @media (min-width: 768px) {
          .button-content {
            font-size: 24px;
            line-height: 36px;
            padding: 7px 42px;
        }

        @media (min-width: 1200px) {
          }
      `}</style>
    </div>
  );
};

ShoppingButton.propTypes = {
  text: PropTypes.string,
  btnStyle: PropTypes.object,
};

ShoppingButton.defaultProps = {
  text: '',
  btnStyle: {},
};

export default ShoppingButton;