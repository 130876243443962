import React from 'react';
import ServiceCard from './serviceCard';

const ServiceList = () => {
  return (
    <div className="content">
      <ServiceCard
        imageUri="https://via.placeholder.com/120"
        title="免額外付費運送"
        text="可選擇免額外付費送貨到府服務，或在合作通路領取現貨商品。"
        showActionBtn={true}
      />
      <ServiceCard
        imageUri="https://via.placeholder.com/120"
        title="零利率分期"
        text="提供零利率分期優惠。"
        showActionBtn={true}
      />
      <ServiceCard
        imageUri="https://via.placeholder.com/120"
        title="取得操作協助"
        text="如果有疑問，歡迎於上班時段致電與專員交流。"
        showActionBtn={false}
      />
      <ServiceCard
        imageUri="https://via.placeholder.com/120"
        title="個人線上課程"
        text="從基本操作到專業知識，我們的線上教學幫助你發揮 Here Hear 的全部功能。"
        showActionBtn={false}
      />
      <style jsx>{`
        .content {
          margin: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        @media (min-width: 768px) {
          .content {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            margin: 0 40px;
          }
        }

        @media (min-width: 1200px) {
        }
      `}</style>
    </div>
  );
};

ServiceList.propTypes = {};

ServiceList.defaultProps = {};

export default ServiceList;
